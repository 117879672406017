
    export default {
        props: {
            value: {
                type: String,
                required: true,
                default: '',
            },
            copy: {
                type: String,
                required: true,
                default: '(Min: %%MIN%%, Left: %%MAX%%)',
            },
            maxlength: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        computed: {
            counterCopy () {
                return this.copy.replace('%%MAX%%', this.maxlength - this.value.length);
            },
        },
    };
