
    import { mapActions, mapMutations } from 'vuex';
    import { RenderlessValidator } from 'rocketship-validator';
    import { BaseInput } from '@components/form';
    import { login as validatorGuards } from '../../../lib/validation/client-validators';
    import store from '../store/index';
    import openModal, { ErrorModal } from '../modals';
    import { setRecaptchaV3Challenge } from '../recaptcha';

    export default {

        components: {
            RenderlessValidator,
            BaseInput,
        },

        props: {
            hideInputs: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['logged-in', 'not-found'],

        data () {
            return {
                loading: false,
                tempProfile: { ...store.state.profile },
                validatorGuards,
            };
        },

        i18nOptions: { namespaces: ['profile', 'intro'] },

        methods: {
            ...mapActions({
                logIn: 'profile/logIn',
            }),

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            async doLogin () {
                const { tempProfile } = this;

                tempProfile.x_channel = store.state.app.channel || undefined;

                await setRecaptchaV3Challenge({ data: tempProfile, action: 'login' });
                await this.$refs.validator.validate();

                if (this.$refs.validator.isValid) {
                    this.$webAnalytics.track('Valid Submission', { category: 'Login Form' });
                }
                else {
                    // Don't continue submitting.
                    return;
                }

                this.loading = true;

                try {
                    await this.logIn({ tempProfile });
                    this.loading = false;
                    this.$emit('logged-in');
                }
                catch (error) {
                    this.loading = false;

                    const { response } = error;

                    if (response && response.status === 404) {
                        this.updateProfile(tempProfile);
                        this.$emit('not-found');
                    }
                    else if (response && response.data && response.data.errors) {
                        this.$refs.validator.setAllErrors(response.data.errors);
                    }
                    else {
                        openModal(ErrorModal);
                    }
                }
            },
        },
    };
