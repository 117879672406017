
    import BaseModal from './BaseModal';

    export default {
        name: 'DuplicateFacilityModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['dup_facility_modal', 'buttons', 'modal_content'] },

        props: {
            facilityId: {
                type: String,
                default: '',
            },
        },

        methods: {
            goToFacility () {
                this.$router.push({ path: `community/${this.facilityId}` });
                this.closeModal();
            },
        },

    };
