
    import { mapState, mapGetters, mapActions } from 'vuex';
    import HockeyvilleLogo from '@components/svgs/HockeyvilleLogo.vue';
    import NHLLogos from '@components/NHLLogos.vue';
    import TearSVG from '@components/svgs/TearSVG.vue';
    import { openPopup } from '../popup';

    export default {
        name: 'TheFooter',
        components: {
            HockeyvilleLogo,
            NHLLogos,
            TearSVG,
        },
        i18nOptions: { namespaces: ['footer', 'links', 'global', 'buttons'] },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),
        },
    };

