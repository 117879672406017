
// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    CommunityPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/CommunityPage.vue'
    ),
    CommunityNotFoundPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/CommunityNotFoundPage.vue'
    ),
    GalleryPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/GalleryPage.vue'
    ),
    RallyToolsPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/RallyToolsPage.vue'
    ),
    HowItWorksPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/HowItWorksPage.vue'
    ),
    LegacyPage = async () => import(
        /* webpackChunkName: 'static' */
        '../views/LegacyPage.vue'
    );

export default [
    { path: '/community/:facility_id', component: CommunityPage, meta: { public: true } },
    { path: '/community/:facility_id/entry/:entry_id', component: CommunityPage, meta: { public: true } },
    { path: '/error/community', component: CommunityNotFoundPage, meta: { public: true } },
    { path: '/gallery', component: GalleryPage, meta: { public: true } },
    { path: '/how-it-works', component: HowItWorksPage, meta: { public: true, activeInPhase: ':any' } },
    { path: '/legacy', component: LegacyPage, meta: { public: true, activeInPhase: ':any' } },
    { path: '/rally-tools', component: RallyToolsPage, meta: { public: true } },
];
