
    import BaseModal from './BaseModal';

    export default {
        extends: BaseModal,

        props: {
            type: {
                type: String,
                default: 'story',
            },
        },

        i18nOptions: { namespaces: ['buttons', 'entry_form', 'entry_confirmation', 'modal_content'] },
    };
