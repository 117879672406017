
// https://github.com/zloirock/core-js#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

if (process.env.VUE_APP_SUPPORT_IE_11 === 'true') {
    // Ensure support for Promises in dynamic imports, i.e. support IE 11.
    // https://babeljs.io/docs/en/babel-plugin-syntax-dynamic-import#working-with-webpack-and-babel-preset-env
    // 'eager' prevents actually splitting into a separate chunk.
    // https://webpack.js.org/api/module-methods/#magic-comments
    import(/* webpackMode: 'eager', webpackChunkName: 'ie11' */ 'core-js/modules/es.promise');
    import(/* webpackMode: 'eager', webpackChunkName: 'ie11' */ 'core-js/modules/es.array.iterator');

    // Polyfill used for DOMTokenList methods that don't work in IE 11.
    import(/* webpackMode: 'eager', webpackChunkName: 'ie11' */ 'dom4');
}

import './client-error';

import Vue from 'vue';
import VueRouter from 'vue-router';

import webAnalytics, { VuePlugin as WebAnalyticsVuePlugin } from './plugins/WebAnalytics';
import startup from './startup';
import './i18n';
import store from './store/index';
import I18nAssetsPlugin from './plugins/I18nAssetsPlugin';

Vue.use(WebAnalyticsVuePlugin, { webAnalytics });
Vue.use(I18nAssetsPlugin);
Vue.use(VueRouter);

(async () => {
    console.log('initializing client-side app in %s mode', process.env.NODE_ENV);

    await startup();

    // We want to wait to load these until after startup, b/c
    // certain views rely on data in the Vuex store having
    // already been initialized.
    const [{ default: App }, { default: router }] = await Promise.all([
        // 'eager' prevents actually splitting into a separate chunk.
        // https://webpack.js.org/api/module-methods/#magic-comments
        import(/* webpackMode: 'eager', webpackChunkName: 'app' */ './App.vue'),
        import(/* webpackMode: 'eager', webpackChunkName: 'router' */ './router'),
    ]);

    window.vueApp = new Vue({
        el: '#app',

        store,
        router,

        render (createElement) {
            return createElement(App);
        },
    });
})();
