
    import { mapActions, mapState } from 'vuex';
    import openModal, { RegistrationModal, LoginModal } from '../modals';

    export default {

        name: 'HeaderCTA',

        props: {
            isLoggedIn: {
                type: Boolean,
                default: false,
            },

            endToEnd: {
                type: String,
                default: '',
            },

            endToEndLogout: {
                type: String,
                default: '',
            },
        },

        i18nOptions: { namespaces: ['intro', 'buttons', 'registration', 'profile', 'global', 'ineligible'] },

        computed: {

            ...mapState({
                profile: (state) => state.profile,
            }),

            hasFavoriteFacility () {
                return Object.prototype.hasOwnProperty.call(this.profile, 'my_facility') && this.profile.my_facility != false;
            },
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            openRegModal () {
                openModal(RegistrationModal);
            },

            openLoginModal () {
                openModal(LoginModal);
            },

            trackUrchinHeaderLink (action, category) {
                this.$webAnalytics.track(action, { category });
            },
        },

    };
