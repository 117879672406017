
import axios from 'axios';
import api from './api';

const community = {
    async getFacilityByID (facility_id) {
        try {
            const response = await axios.get(`${api.base}/facilities/facility/${facility_id}`);
            if (response.status === 200) {
                const { facility } = response.data;
                return facility.data;
            }
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    },

    /**
     * Returns the short link to a facility
     * @param {string|Object} facility The string ID to the facility
     * or the facility object with either .display_id or .id on it
     * @returns {string} The short link to the facility
     */
    getFacilityPath (facility) {
        const id = typeof facility !== 'object' ?
            facility : facility.facilityId ? facility.facilityId : facility.data.id;
        return `/community/${id}`;
    },

    async submitEntry (params) {
        try {
            const response = await axios.post(`${api.base}/contest/entries/`, params);
            return response;
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    },
};

export default community;
