
    import RegisterForm from '@components/RegisterForm';
    import BaseModal from './BaseModal';

    export default {
        name: 'RegistrationModal',
        components: {
            RegisterForm,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['registration', 'modal_content'] },

        data () {
            return {
                modalName: 'register',
            };
        },

    };
