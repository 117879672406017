

    import { mapState } from 'vuex';

    export default {
        name: 'LanguageToggle',

        computed: {
            ...mapState(['app']),
        },

        methods: {
            trackUrchinHeader (action, category) {
                this.$webAnalytics.track(action, { category });
            },
        },

        i18nOptions: { namespaces: ['links', 'global'] },
    };
