

import { mapState, mapGetters } from 'vuex';

import { track } from '../plugins/WebAnalytics';
import store from '../store';
import i18n from '../i18n';
import router from '../router';

export default {
    name: 'BaseModal',

    data () {
        return {
            modalName: '',
        };
    },

    // Modals are started up as a completely separate Vue app; we add some
    // properties here so they have the same functionality as the main app.
    i18n,
    router,
    store,

    computed: {
        // Map state very commonly used by modals, for convenience.
        ...mapState([
            'app',
            'profile',
        ]),

        ...mapGetters({
            isSessionExpired: 'profile/isSessionExpired',
        }),

    },

    watch: {
        isSessionExpired () {
            if (this.modalName !== 'session-expiration') {
                this.closeModal();
            }
        },
    },

    mounted () {
        document.body.setAttribute('data-e2e-modal', this.modalName);

        store.commit('ui/modalOpen', this);

        track('Open', { category: 'Modal', label: this.modalName });
    },

    beforeDestroy () {
        document.body.removeAttribute('data-e2e-modal');

        store.commit('ui/modalClose');

        track('Close', { category: 'Modal', label: this.modalName });
    },

    methods: {
        // `arg` in either of these methods will end up resolved as the
        // value of the openModal promise
        cancelModal (arg) {
            this.$emit('cancel-modal', arg);
        },

        closeModal (arg) {
            this.$emit('close-modal', arg);
        },
    },
};
