
    import HockeyvilleLogo from '@components/svgs/HockeyvilleLogo.vue';
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        components: {
            HockeyvilleLogo,
        },

        extends: BasePage,

        data () {
            return {
                pageName: 'error',
            };
        },
        i18nOptions: { namespaces: ['error'] },
    };
