
    import { mapState } from 'vuex';

    export default {
        name: 'SocialIcons',

        props: {
            trackingPrefix: {
                type: String,
                default: '',
            },
        },

        i18nOptions: { namespaces: ['links'] },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
        },

        methods: {
            trackUrchinSocial (action, category) {
                this.$webAnalytics.track(action, { category });
            },
        },
    };
