
    import axios from 'axios';
    import Validator from '@components/Validator';
    import { RenderlessValidator } from 'rocketship-validator';
    import { BaseInput, BaseSelect } from '@components/form';
    import openModal, { LoginModal, AddFacilityModalThanks, PendingApprovalModal, DuplicateFacilityModal } from '../modals';
    import { facilityAdd as validatorGuards } from '../../../lib/validation/client-validators';
    import api from '../api';
    import BaseModal from './BaseModal';

    export default {
        name: 'AddFacilityModal',

        components: {
            BaseInput,
            BaseSelect,
            RenderlessValidator,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['add_facility', 'global', 'modal_content'] },

        validatorGuards,

        props: {
            suggestedFacilityData: {
                type: Object,
                default: () => {},
            },
        },

        data () {
            return {
                modalName: 'add-facility',
                states: [],
                tempFacility: {
                    city: '',
                    country: 'CA',
                    name: '',
                    state: '',
                    zip: '',
                },
            };
        },

        computed: {
            stateOptions () {
                return this.states && this.states.map(
                    (state) => ({
                        label: this.$t(`global:state.${state}.name`) || state,
                        value: state,
                    }),
                ) || [{ label: '', value: '' }];
            },
        },

        created () {
            this.loadConfig();
        },

        methods: {
            async addFacility () {
                if (!this.profile.id) {
                    openModal(LoginModal);
                }
                else {
                    await this.$refs.validator.validate();
                    if (!this.$refs.validator.isValid) {
                        return;
                    }
                    try {
                        const response = await axios.post(`${api.base}/facilities`, this.tempFacility);
                        this.closeModal();
                        openModal(AddFacilityModalThanks);
                        try { window.fbq('track', 'Lead'); } // Facebook Pixel
                        catch (e) { return; }
                    }
                    catch (err) {
                        if (err && err.response && err.response.data && err.response.data.status === 'pending') {
                            this.closeModal();
                            openModal(PendingApprovalModal);
                        }
                        else if (err && err.response && err.response.data && err.response.data.status === 'approved') {
                            this.closeModal();
                            openModal(DuplicateFacilityModal, { facilityId: err.response.data.facilityId });
                        }
                        else {
                            this.closeModal();
                            this.$router.push('/error');
                        }
                    }
                }
            },

            handleSuggestedFacilityData (data) {
                if (data && data.name) {
                    this.tempFacility.name = data.name;
                }

                if (data && data.address && data.address.city) {
                    this.tempFacility.city = data.address.city;
                }

                if (data && data.address && data.address.stateShort) {
                    const matchingState = this.states.find(state => state.includes(data.address.stateShort));
                    if (matchingState) {
                        this.tempFacility.state = matchingState;
                    }
                }

                if (data && data.address && data.address.zipCode) {
                    this.tempFacility.zip = data.address.zipCode;
                }
            },

            async loadConfig () {
                const response = await axios.get(`${api.base}/states/config`);

                this.states = response.data.states;

                this.handleSuggestedFacilityData(this.suggestedFacilityData);
            },
        },
    };
