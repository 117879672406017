
    import { mapState } from 'vuex';
    import NHLLogoEN from '@components/svgs/NHLLogoEN.vue';
    import NHLLogoFR from '@components/svgs/NHLLogoFR.vue';

    export default {
        name: 'NHLLogos',

        components: {
            NHLLogoEN,
            NHLLogoFR,
        },

        computed: {
            ...mapState(['app']),

            isFR () {
                return this.app.locale === 'fr';
            },
        },
    };
