
    import BaseModal from './BaseModal';

    export default {
        name: 'PendingApprovalModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['pending_approval', 'buttons', 'add_facility'] },

        data () {
            return {

            };
        },

    };
