
/*
    Renderless Vue component providing reactive `rocketship-validator` functionality.
    https://adamwathan.me/renderless-components-in-vuejs/
*/

import { defineComponent } from 'vue';
import Validator from 'rocketship-validator';

// TODO: If I'm popular and stable, move me into core `rocketship-validator`.
export default defineComponent({
    props: {
        guards: {
            type: Object,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
        watch: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            allErrors: [],
            // We're not supposed to change a prop, since that's a child manipulating its parent's
            // state. So the original prop is aliased into our own data here, so that we can freely
            // change it from the original value.
            // https://vuejs.org/v2/guide/components-props.html#One-Way-Data-Flow
            watchData: this.watch,
        };
    },

    computed: {
        validator () {
            return new Validator(this.guards);
        },

        errorsByName () {
            // Reduce the array into an object of errors keyed by their names.
            // `[{ name: 'age', ... }, { name: 'email', ... }]` =>
            // `{ age: { name: 'age', ... }, email: { name: 'email', ... } }`
            return this.allErrors.reduce((errorsByName, error) => {
                if (!(error.name in errorsByName)) errorsByName[error.name] = error;
                return errorsByName;
            }, {});
        },

        isValid () {
            return !this.hasErrors;
        },

        hasErrors () {
            return this.allErrors.length > 0;
        },
    },

    watch: {
        data: {
            handler () {
                if (this.watchData) {
                    this.validate();
                }
            },
            deep: true,
        },
    },

    methods: {
        isError (name) {
            return name in this.errorsByName;
        },

        isAnyError (errorStr) {
            return this.allErrors.some((error) => errorStr === error.error);
        },

        isAnyErrorMessageLike (messageRegex) {
            return this.allErrors.some((error) => (messageRegex).test(error.message));
        },

        async validate () {
            this.resetErrors();

            try {
                await this.validator.validate(this.data);
            }
            catch (err) {
                // Start watching so that users can see their fixes in real-time.
                this.watchData = true;

                if (err.validation) {
                    this.setAllErrors(err.validation.getAllErrors());
                    return;
                }
                throw err;
            }
        },

        resetErrors () {
            this.allErrors = [];
        },

        setAllErrors (allErrors) {
            console.error('validation errors', allErrors, JSON.stringify(allErrors));
            this.allErrors = allErrors;
        },

        addError (error) {
            this.allErrors.push(error);
        },
    },

    render (createElement) {
        // Pass all our data and methods to our default scoped slot for rendering.
        return createElement('div', this.$scopedSlots.default(this));
    },
});
