
    export default {
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                default: '',
                required: true,
            },
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            error: {
                type: String,
                required: true,
                default: '',
            },
            name: {
                type: String,
                required: true,
                default: '',
            },
            minlength: {
                type: Number,
                required: false,
                default: 0,
            },
            maxlength: {
                type: Number,
                require: false,
                default: 0,
            },
        },
        data () {
            return {
                textAreaValue: '',
            };
        },
        mounted () {
            this.$nextTick(() => {
                const input = this.$refs[`input_${this._uid}`];
                if (input.attributes.autofocus) {
                    input.focus();
                }
            });
        },
        methods: {
            onTextareaUpdate (event) {
                this.textAreaValue = event.target.value;
                if (this.textAreaValue.length > this.maxlength) {
                    const limitedTextLength = this.textAreaValue.slice(0, Math.max(0, this.maxlength));
                    this.textAreaValue = limitedTextLength;
                }
                this.$emit('input', this.textAreaValue);
            },
        },
    };
