
    export default {
        inheritAttrs: false,
        props: {
            'value': {
                type: String,
                default: '',
                required: true,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            isError: {
                type: Boolean,
                default: false,
            },
            name: {
                type: String,
                required: true,
                default: '',
            },
            error: {
                type: String,
                required: true,
                default: '',
            },
            accept: {
                type: String,
                required: true,
                default: '',
            },
            buttonLabel: {
                type: String,
                required: true,
                default: '',
            },
            maxFileSize: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        mounted () {
            this.$nextTick(() => {
                const input = this.$refs[`input_${this._uid}`];
                if (input.attributes.autofocus) {
                    input.focus();
                }
            });
        },

        methods: {
            browseClick () {
                const input = this.$refs[`input_${this._uid}`];
                input.click();
            },
            updateFile (event) {
                const input = this.$refs[`input_${this._uid}`],
                      accepted = input.getAttribute('accept'),
                      reader = new FileReader(),
                      file = event.target.files[0],
                      nameSplit = file.name.split('.'),
                      extension = nameSplit[nameSplit.length - 1].toLowerCase(),
                      fileSize = file.size,
                      fileName = file.name;

                if (!accepted.includes(extension)) {
                    this.$emit('selected', { error: 'format' });
                }
                else if (fileSize > this.maxFileSize) {
                    this.$emit('selected', { error: 'too_large' });
                }
                else {
                    reader.onload = (evt) => {
                        this.$emit('selected', { photo: evt.target.result, name: fileName, file });
                    };
                    reader.readAsDataURL(file);
                }
            },
        },
    };
