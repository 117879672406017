
    import axios from 'axios';
    import parsePlace from 'parse-google-place';
    import Validator from '@components/Validator';
    import FacilityList from '@components/FacilityList.vue';
    import { BaseSelect } from '@components/form';
    import openModal, { AddFacilityModal } from '../modals';
    import { facilitySearch as validatorGuards } from '../../../lib/validation/client-validators';
    import facilities from '../facilities';
    import api from '../api';
    import BaseModal from './BaseModal';

    export default {
        name: 'FindFacilityGoogleModal',

        components: {
            Validator,
            FacilityList,
            BaseSelect,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['add_facility', 'facility_search', 'global', 'profile', 'modal_content'] },

        validatorGuards,

        data () {
            return {
                modalName: 'find-facility-google',
                // Form fields
                tempSearch: {
                    state: '',
                    query: '',
                },
                states: [],
                placeId: '',  // GoogleMaps autocomplete selection ID

                hasSubmitted: false,
                // ? TODO remove this if we keep the button disabled
                isFacilityRequestPending: false, // avoid user double-clicks by disabling submit button
                facilities: [],
                map: {},
                tempPlace: {}, // GoogleMaps 'place'; used to prefill form fields in AddFacility
                googleSuggestion: {},
            };
        },

        computed: {
            addFacilityCopy () {
                return `${this.$t('add')} ${this.tempPlace.name}`;
            },
            showNominateNewFacility () {
                return this.isPlaceSuggestion && this.isEntryPhase && this.isResultEmpty && this.hasSubmitted && !this.isFacilityRequestPending;
            },
            showNoResults () {
                return this.isResultEmpty && this.hasSubmitted && !this.isFacilityRequestPending;
            },
            isEntryPhase () {
                return ['launched', 'entry'].includes(this.app.phase);
            },
            isFacilityQueryEmpty () {
                return this.tempSearch.state == '' || this.tempSearch.state == '0' || this.tempSearch.query.trim() === '';
            },
            isPlaceSuggestion () {
                return Object.keys(this.tempPlace).length > 0;
            },
            isResultEmpty () {
                return this.facilities.length <= 0;
            },
            stateOptions () {
                return this.states && this.states.map(
                    (state) => ({
                        label: this.$t(`state.${state}.name`) || state,
                        value: state,
                    }),
                ) || [{ label: '', value: '' }];
            },
        },

        created () {
            this.loadConfig();
        },

        async mounted () {
            this.setupGoogleMaps();
        },

        methods: {
            openAddFacilityModal () {
                this.closeModal();
                openModal(AddFacilityModal, { suggestedFacilityData: this.parseGoogleMapsPlaceData(this.tempPlace) });
            },

            clearMap () {
                this.selectedMarker = null;
                this.facilities = [];
                if (this.markers) {
                    for (let i = 0; i < this.markers.length; i++) {
                        window.google.maps.event.clearInstanceListeners(this.markers[i].marker);
                        this.markers[i].marker.setMap(null);
                    }
                }
                this.markers = [];
            },

            clearSearchForm () {
                this.placeId = '';
            },

            async getFacilities (payload) {
                this.hasSubmitted = true;
                this.isFacilityRequestPending = true;
                let facilitiesResponse;
                // this.trackEvent('submit', `facility-search:${payload.query}-in-${payload.state}`);
                try {
                    // * Endpoint's syntax is
                    // *   /api/facility/search?state=MI&query=NAME|ADDRESS&place_id=HASH
                    // *   where place_id is the Google Maps autocomplete result
                    // *   and place_id takes priority over other parameters
                    // *
                    // * If sending state and query, API will filter on the state and
                    // *   then look for query inside of name, city, and zip
                    facilitiesResponse = await facilities.search(payload);
                    this.isFacilityRequestPending = false;
                }
                catch (err) {
                    console.error(err);
                    this.isFacilityRequestPending = false;
                    // this.$router.push({ path: `/error` });
                    return;
                }

                const facilitiesList = facilitiesResponse.data.result;

                if ( // if response was empty (null or [] )
                    facilitiesList == null ||
                    facilitiesList.length < 1
                ) {
                    if (this.isPlaceSuggestion) {
                        this.googleSuggestion = this.parseGoogleMapsPlaceData(this.tempPlace);
                    }
                    this.clearSearchForm();
                    return;
                }

                return this.updateFacilities(facilitiesList);
            },

            findMarkerByFacilityID (facilityID) {
                const findMarker = this.markers.find((marker) => marker.facility.data.id === facilityID);
                return findMarker.marker;
            },

            onSearchFacilities () {
                if (this.isFacilityQueryEmpty) {
                    return;
                }

                // Clear markers to prevent memory leak
                this.clearMap();

                const split = this.tempSearch.state.split('-'),
                      tempState = split.length > 1 ? split[1] : split[0];

                const payload = {
                    state: tempState,
                    query: this.tempSearch.query,
                    place_id: this.placeId || '',
                };

                this.getFacilities(payload);
            },

            parseGoogleMapsPlaceData (place) {
                /*
                    return an object that doesnt contain the entire Google Maps 'place' data
                */
                const parsedPlace = {
                    name: place.name,
                    // placeId: place.place_id,
                    // lat: place.geometry.location.lat(),
                    // lng: place.geometry.location.lng(),
                    address: parsePlace(place), // node_module: parse-google-place
                };
                return parsedPlace;
            },

            scrollTo () {
                // Need the slight delay for the class name to be added to the correct element
                setTimeout(() => {
                    const $facilityContainer = this.$refs.facilityListComponent.$el,
                          $selectedFacility = $facilityContainer.querySelector('.selected');

                    $facilityContainer.scrollTo(0, 0);
                    const newTop = ($selectedFacility) ? $selectedFacility.getBoundingClientRect().top - $facilityContainer.getBoundingClientRect().top : 0;
                    $facilityContainer.scrollTo(0, newTop);
                }, 10);
            },

            highlightAndCenterMapMarker (facilityID) {
                const marker = this.findMarkerByFacilityID(facilityID);
                if (this.selectedMarker) {
                    this.selectedMarker.setIcon(this.grayMarker);
                    this.selectedMarker.setZIndex(0);
                }
                marker.setIcon(this.blueMarker);
                marker.setZIndex(9999);
                this.selectedMarker = marker;
                this.map.panTo(marker.getPosition());
                this.$refs.facilityListComponent.selectFacility(facilityID);
            },

            setupGoogleMaps () {
                const ca_center = { lat: 54.7243355, lng: -95.7859694 };
                // const us_center = { lat: 39.8283, lng: -98.5795 };
                const autocomplete = new window.google.maps.places.Autocomplete(
                    this.$refs.mapQuery,
                    {
                        types: ['establishment'],
                        // https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
                        // ? TODO determine if user can search both US and CA
                        componentRestrictions: { country: ['CA'] }, // country's value must be a ISO 3166 country code
                    },
                );
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (!place.place_id) {
                        this.googleSuggestion = {};
                        this.tempPlace = {};
                        return; // early return for no results
                    }

                    // * placeId gets added to the form via hidden <input> before triggering submit button click.
                    this.placeId = place.place_id;
                    this.tempPlace = place;
                    // Submit triggers this.searchFacilities()
                    // We're using the submit button click because it allows us to reuse the form validation.
                    this.tempSearch.query = place.name;
                    this.onSearchFacilities();
                });
                this.map = new window.google.maps.Map(this.$refs.mapContainer, {
                    zoom: 4,
                    center: ca_center,
                });
                this.grayMarker = {
                    fillColor: '#86898b',
                    fillOpacity: 1,
                    scale: 10,
                    strokeWeight: 0,
                    path: window.google.maps.SymbolPath.CIRCLE,
                };
                this.blueMarker = {
                    fillColor: '#1c3c6d',
                    fillOpacity: 0.5,
                    strokeWeight: 2,
                    strokeColor: '#1c3c6d',
                    scale: 10,
                    path: 'M-0.6,0a0.6,0.6 0 1,0 1.2,0 a0.6,0.6 0 1,0 -1.2,0' +
                        'M-0.4,0a0.4,0.4 0 1,0 0.8,0 a0.4,0.4 0 1,0 -0.8,0' +
                        'M-0.2,0a0.2,0.2 0 1,0 0.4,0 a0.2,0.2 0 1,0 -0.4,0' +
                        'M-0.1,0a0.1,0.1 0 1,0 0.2,0 a0.1,0.1 0 1,0 -0.2,0' +
                        'M -1,0a1,1 0 1,0 2,0 a1,1, 0 1,0 -2,0',
                };
            },

            updateFacilities (facilities) {
                if (facilities.length <= 0) {
                    return;
                }

                let minLat = NaN;
                let minLng = NaN;
                let maxLng = NaN;
                let maxLat = NaN;

                for (let i = 0; i < facilities.length; i++) {
                    const facility = facilities[i];
                    facility.lat = Number(facility.data.lat);
                    facility.lng = Number(facility.data.lng);

                    if (isNaN(minLat) || facility.lat < minLat) {
                        minLat = facility.lat;
                    }
                    if (isNaN(minLng) || facility.lng < minLng) {
                        minLng = facility.lng;
                    }
                    if (isNaN(maxLat) || facility.lat > maxLat) {
                        maxLat = facility.lat;
                    }
                    if (isNaN(maxLng) || facility.lng > maxLng) {
                        maxLng = facility.lng;
                    }

                    const newMarker = new window.google.maps.Marker({
                        position: { lat: Number(facility.lat), lng: Number(facility.lng) },
                        title: facility.data.name,
                        map: this.map,
                        icon: this.grayMarker,
                        clickable: true,
                    });

                    this.markers.push({ marker: newMarker, facility });

                    // * addListener(instance: Object, eventName: string, handler: Function)
                    // * Adds the given listener function to the given event name for the given object instance.
                    // * Returns an identifier for this listener that can be used with removeListener().
                    newMarker.addListener('click', this.highlightAndCenterMapMarker.bind(this, facility.data.id));
                }

                this.facilities = facilities;

                // move the map around the new points
                if (!isNaN(minLat)) {
                    const lngDif = maxLng - minLng;
                    const latDif = maxLat - minLat;
                    this.map.fitBounds({ east: maxLng, west: minLng, north: minLat, south: maxLat });
                    const center = { lat: minLat + latDif / 2, lng: minLng + lngDif / 2 };
                    this.map.setCenter(center);
                    window.google.maps.event.trigger(this.map, 'resize');
                }
            },
            async loadConfig () {
                const response = await axios.get(`${api.base}/states/config`);
                const { states, countries } = response.data;

                this.states = states;
                this.countries = countries;
            },
        },
    };
