
    import { mapGetters, mapState } from 'vuex';
    import VueSlickCarousel from 'vue-slick-carousel';
    import openModal, { LoginModal } from '../modals';
    import BaseModal from './BaseModal';


    export default {
        name: 'OnboardingModal',

        components: {
            VueSlickCarousel,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['modal_content'] },

        props: {
        },

        data () {
            return {
                currentSlide: 0,
                modalName: 'onboarding',
            };
        },

        computed: {
            ...mapGetters({
                isLoggedIn: 'profile/loggedIn',
            }),
            ...mapState(['app', 'profile']),
        },

        mounted () {
        },
        methods: {
            slickNext () {
                const { carousel } = this.$refs;
                carousel.next();
            },
            openLoginModal () {
                if (!this.isLoggedIn) {
                    openModal(LoginModal);
                }

                this.closeModal();
            },

            updateSlide (oldIndex, newIndex) {
                this.currentSlide = newIndex;
            },
        },
    };
