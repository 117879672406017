function setDOBArrayData () {
    const dobConfig = {
        months: 12,
        days: 31,
        minYear: 1910,
        maxYear: 2020,
    };
    const days = [];
    for (let i = 1; i <= dobConfig.days; i++) {
        days.push({ label: i, value: i });
    }
    const months = [];
    for (let i = 1; i <= dobConfig.months; i++) {
        months.push({ label: i, value: i });
    }
    const years = [];
    for (let i = dobConfig.minYear; i <= dobConfig.maxYear; i++) {
        years.unshift({ label: i, value: i });
    }
    return {
        days,
        months,
        years,
    };
}
const dobData = setDOBArrayData();
export default dobData;
