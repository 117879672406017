import Vue from 'vue';

export default function openModal (component, props) {
    return new Promise((resolve, reject) => {
        const modal = new Vue({
            methods: {
                closeHandler (fn, arg) {
                    fn(arg);
                    modal.$destroy();
                    // use Node.removeChild instead of ChildNode.remove for compatibility
                    // https://caniuse.com/#search=ChildNode.remove()
                    modal.$el.parentNode.removeChild(modal.$el);
                },
            },

            render (createElement) {
                return createElement(component, {
                    props,
                    on: {
                        'close-modal': (arg) => this.closeHandler(resolve, arg),
                        'cancel-modal': (arg) => this.closeHandler(reject, arg),
                    },
                });
            },
        }).$mount();

        document.body.appendChild(modal.$el);
    });
}

export { default as SessionExpirationModal } from './SessionExpirationModal.vue';
export { default as ErrorModal } from './ErrorModal.vue';
export { default as LoginModal } from './LoginModal.vue';
export { default as RegistrationModal } from './RegistrationModal.vue';
export { default as DuplicateAccountModal } from './DuplicateAccountModal.vue';
export { default as IneligibleModal } from './IneligibleModal.vue';
export { default as FindApprovedFacilityModal } from './FindApprovedFacilityModal.vue';
export { default as FindFacilityGoogleModal } from './FindFacilityGoogleModal.vue';
export { default as StoryFormModal } from './StoryFormModal.vue';
export { default as NominationEssayModal } from './NominationEssayModal.vue';
export { default as NominationVideoModal } from './NominationVideoModal.vue';
export { default as MediaFormModal } from './MediaFormModal.vue';
export { default as NoteFormModal } from './NoteFormModal.vue';
export { default as EntryConfirmationModal } from './EntryConfirmationModal.vue';
export { default as EntryLimitedModal } from './EntryLimitedModal.vue';
export { default as Top4Modal } from './Top4Modal.vue';
export { default as EntryModal } from './EntryModal.vue';
export { default as OnboardingModal } from './OnboardingModal.vue';
export { default as AddFacilityModal } from './AddFacilityModal.vue';
export { default as PendingApprovalModal } from './PendingApprovalModal.vue';
export { default as AddFacilityModalThanks } from './AddFacilityModalThanks.vue';
export { default as DuplicateFacilityModal } from './DuplicateFacilityModal.vue';
