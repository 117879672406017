
    import { mapGetters } from 'vuex';
    import Validator from '@components/Validator';
    import { BaseTextarea, BaseTextareaCounter } from '@components/form';
    import { storyEntry as validatorGuards } from '../../../lib/validation/client-validators';
    import { openPopup } from '../popup';
    import community from '../community';
    import BaseModal from './BaseModal';
    import openModal, { EntryConfirmationModal, EntryLimitedModal } from '.';


    export default {
        name: 'NominationEssayModal',

        components: {
            Validator,
            BaseTextarea,
            BaseTextareaCounter,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'story-form',
                loading: false,
                min: 150,
                max: 1000,
                tempForm: {
                    type: 'story',
                    question1: '',
                    question2: '',
                    question3: '',
                },
                errorLength: false,
            };
        },

        computed: {
            ...mapGetters({
                getFacility: 'app/getFacility',
            }),
        },

        i18nOptions: { namespaces: ['entry_form', 'buttons', 'modal_content'] },

        validatorGuards,

        methods: {

            openFAQ (event) {
                if (event.target.tagName == 'A') {
                    event.preventDefault();
                    openPopup(event);
                }
            },

            async doSubmit () {
                const { tempForm } = this;
                const { validator } = this.$refs;

                tempForm.facilityId = this.getFacility.id;
                tempForm.facilityName = this.getFacility.name;
                tempForm.facilityProvince = this.getFacility.state;

                this.errorLength = false;

                await validator.validate();

                if (!validator.isValid) {
                    const questionErrors = Object.keys(validator.errorsByName)
                        .filter(element => validator.errorsByName[element].error.includes('NULL') || element.includes('rules'));
                    if (!questionErrors.length) {
                        this.errorLength = true;
                    }
                    return;
                }

                this.loading = true;

                try {
                    await community.submitEntry(tempForm);

                    this.loading = false;
                    this.closeModal();
                    openModal(EntryConfirmationModal);
                }
                catch (error) {
                    this.loading = false;
                    this.closeModal();
                    const typeOfError = error.response.data.result.error;
                    if (typeOfError === 'limited') {
                        openModal(EntryLimitedModal, { type: 'story' });
                    }
                    else {
                        this.$router.push('/error');
                    }
                }
            },
        },
    };
