
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { page } from '../plugins/WebAnalytics';
import store from '../store';

export default defineComponent({
    name: 'BasePage',

    beforeRouteEnter (to, from, next) {
        next(vm => {
            store.commit('ui/pageEnter', vm);

            const { query: { affiliate_id } } = to;

            // If there was an affiliate in the "query string" embedded in the Vue
            // Router hash portion of the URL, pass it along to the app store.
            if (affiliate_id) {
                store.commit('app/hashAffiliateUpdate', affiliate_id);
            }

            page();
        });
    },

    beforeRouteLeave (to, from, next) {
        store.commit('ui/pageLeave');

        next();
    },

    data () {
        return {
            pageName: '',
            pageClasses: [],
        };
    },

    computed: {
        // Map state very commonly used by pages, for convenience.
        ...mapGetters({
            inPhase: 'app/inPhase',
        }),
        ...mapState([
            'app',
            'profile',
        ]),
    },

    mounted () {
        document.body.setAttribute('data-e2e-page', this.pageName);
    },

    beforeDestroy () {
        document.body.removeAttribute('data-e2e-page');
    },

    methods: {
        find (selector) {
            if (!this.$el) return;
            return this.$el.querySelector(selector);
        },

        scrollToTop () {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    },
});
