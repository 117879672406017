
    import { mapState } from 'vuex';
    import axios from 'axios';
    import api from '../api';

    export default {
        name: 'EntryReactions',

        props: {
            'entryId': {
                required: true,
                type: String,
            },
            'facilityId': {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                currentReaction: '',
                reactions: [
                    'like',
                    'love',
                    'laugh',
                    'wow',
                    'sad',
                ],
            };
        },

        computed: {
            ...mapState(['profile']),
        },

        async created () {
            if (this.profile.id) {
                const response = await axios.get(`${api.base}/contest/entries/${this.entryId}/reaction`);
                this.currentReaction = response.data.reaction || '';
            }
        },

        methods: {
            async submitReaction (reaction) {
                if (this.profile.id && !this.currentReaction) {
                    const response = await axios.post(
                        `${api.base}/contest/entries/${this.entryId}/reaction`,
                        { reaction, facilityId: this.facilityId },
                    );
                    if (response.data.result.status === 1) {
                        this.currentReaction = reaction;
                        this.$webAnalytics.track('reaction', { category: this.currentReaction });
                    }
                }
            },
        },
    };
