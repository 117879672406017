// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DmcaPage = async () => import(
        /* webpackChunkName: 'dmca' */
        '../views/DmcaPage.vue'
    );
export default [
    { path: '/dmca', component: DmcaPage, meta: {
        public: true,
        activeInPhase: ':any',
    } },
];
