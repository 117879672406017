
    import VideoForm from '@components/VideoForm.vue';
    import { openPopup } from '../popup';
    import BaseModal from './BaseModal';
    import openModal, { EntryConfirmationModal, EntryLimitedModal } from '.';

    export default {
        name: 'NominationVideoModal',

        components: {
            VideoForm,
        },

        extends: BaseModal,

        data () {
            return {
                modalName: 'media-form',
                videoParams: {
                    types: 'mp4, mov, ogv, webm, avi, dv, qt, mpg, mpg2, mpg4, 3gp, 3g2, asf, wmv',
                    max: 100 * 1024 * 1024,
                },
            };
        },

        i18nOptions: { namespaces: ['entry_form', 'buttons', 'modal_content'] },

        methods: {
            openFAQ (event) {
                if (event.target.tagName == 'A') {
                    event.preventDefault();
                    openPopup(event);
                }
            },
            openResults (result) {
                this.closeModal();
                if (result == 'success') {
                    openModal(EntryConfirmationModal);
                }
                else {
                    openModal(EntryLimitedModal, { type: 'story' });
                }
            },
        },
    };
