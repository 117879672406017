
    import openModal, { NominationEssayModal, NominationVideoModal } from '../modals';
    import BaseModal from './BaseModal';


    export default {
        name: 'StoryFormModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'story-form',
            };
        },

        i18nOptions: { namespaces: ['entry_form', 'buttons', 'modal_content'] },

        methods: {

            openEssayModal () {
                this.closeModal();
                openModal(NominationEssayModal);
            },

            openVideoModal () {
                this.closeModal();
                openModal(NominationVideoModal);
            },
        },
    };
