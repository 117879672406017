
    import BaseModal from './BaseModal';

    export default {
        name: 'SessionExpirationModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'session-expiration',
            };
        },

        i18nOptions: { namespaces: ['session', 'buttons', 'modal_content'] },
    };
