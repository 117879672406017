
    import BaseModal from './BaseModal';

    export default {
        name: 'EntryConfirmationFormModal',
        extends: BaseModal,
        props: {
            confirmationFor: {
                required: false,
                type: String,
                default: '',
            },
        },
        i18nOptions: { namespaces: ['entry_confirmation', 'buttons', 'modal_content'] },

        data () {
            return {
                modalName: 'entry-confirmation',
            };
        },
    };
