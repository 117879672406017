
import Vue from 'vue';
import Vuex from 'vuex';

const modules = requireAllStoreModules();

function requireAllStoreModules () {
    // `require.context` allows us to bundle all files in a directory that match a regex.
    // https://webpack.js.org/guides/dependency-management/#requirecontext
    const r = require.context('./modules', /* include subdirectories */ false, /\.js$/);
    const moduleObjects = r.keys().map(id => ({
        [moduleIdToName(id)]: r(id).default,
    }));
    return Object.assign({}, ...moduleObjects);
}

function moduleIdToName (id) {
    return id.replace(/^\.\//, '').replace(/\.js$/, '');
}

Vue.use(Vuex);

export default new Vuex.Store({
    modules,

    strict: process.env.NODE_ENV !== 'production',
});
