
import axios from 'axios';
import api from './api';
import store from './store/index';

const uploadTypes = new Set(['photo', 'video']);

const uploader = {
    async key (whichType) {
        const params = {
            uid: store.state.profile.id,
            type: whichType,
            language: store.state.app.userLanguage,
        };

        return axios.post(`${api.base}/uploader/key`, params);

    },
    async upload (params, keyData) {
        const formData = new FormData();
        formData.append('upload', params.file, params.name);

        // XHR was the only way I could get it to work, for now
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${keyData.data.uploadHost}/uploader/v3/upload/?key=${keyData.data.key}`, true);
            xhr.setRequestHeader('Accept', 'application/json');

            xhr.onload = () => {
                const response = JSON.parse(xhr.responseText);
                params[params.type] = params.type === 'photo' ? response.outputs.gallery.url : response.outputs.orig.transcodeJob.outputs[0].url;
                resolve(params);
            };
            xhr.onerror = (err) => {
                reject(err);
            };

            xhr.send(formData);
        });
    },
    async entries (params) {
        try {
            const response = axios.post(`${api.base}/contest/entries/`, params);
            return response;
        }
        catch (err) {
            return err;
        }
    },
    async submit (params, isYoutube) {
        if (!uploadTypes.has(params.type) || isYoutube) {
            const nonUploaderResponse = this.entries(params);
            return nonUploaderResponse;
        }

        const keyData = await this.key(params.type),
            uploaderResponse = await this.upload(params, keyData);
        return this.entries(uploaderResponse);
    },
};

export default uploader;
