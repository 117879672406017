
import axios from 'axios';
import api from './api';

const facilities = {
    async search (payload) {
        try {
            const response = await axios.get(`${api.base}/facilities/search`, {
                params: payload,
            });

            return response;
        }
        catch (err) {
            console.error(err);
            // openModal(ErrorModal);
            throw err;
        }
    },
    async all () {
        try {
            const response = await axios.get(`${api.base}/facilities/`);

            return response;
        }
        catch (err) {
            console.error(err);
            // openModal(ErrorModal);
            throw err;
        }
    },
};

export default facilities;
