
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
    import SocialIcons from '@components/SocialIcons.vue';
    import HeaderCTA from '@components/HeaderCTA.vue';
    import LanguageToggle from '@components/LanguageToggle.vue';
    import { openPopup } from '../popup';
    import openModal, { RegistrationModal, LoginModal, FindApprovedFacilityModal } from '../modals';

    export default {
        name: 'HeaderNavigation',

        i18nOptions: { namespaces: ['links', 'global'] },

        components: {
            HeaderCTA,
            SocialIcons,
            LanguageToggle,
        },

        data () {
            return {
                isSmallLayout: false,
            };
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                isLoggedIn: 'profile/loggedIn',
                inPhase: 'app/inPhase',
                loggedIn: 'profile/loggedIn',
            }),
        },

        watch: {
            '$route': 'hideMenu',
        },

        mounted () {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
                this.onResize();
            });
        },

        beforeDestroy () {
            window.removeEventListener('resize', this.onResize);
        },

        methods: {
            openPopup,

            ...mapActions({
                logOut: 'profile/logOut',
            }),

            ...mapMutations({
                openMenu: 'ui/openMenu',
                closeMenu: 'ui/closeMenu',
            }),

            hideMenu () {
                this.closeMenu();
                this.trackUrchinHeader('header-hide-mobile-menu', 'click');
            },

            showMenu () {
                this.openMenu();
                this.trackUrchinHeader('header-show-mobile-menu', 'click');
            },

            openFindApprovedFacilityModal () {
                openModal(FindApprovedFacilityModal);
            },

            openRegModal () {
                openModal(RegistrationModal);
            },

            openLoginModal () {
                openModal(LoginModal);
            },

            trackUrchinHeader (action, category) {
                this.$webAnalytics.track(action, { category });
            },

            onResize () {
                this.isSmallLayout = window.innerWidth <= 768;
            },
        },
    };

