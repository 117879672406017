
    import { mapActions, mapMutations } from 'vuex';
    import LoginForm from '@components/LoginForm.vue';
    import openModal, { RegistrationModal } from '../modals';
    import BaseModal from './BaseModal';

    export default {
        name: 'LoginModal',

        components: {
            LoginForm,
        },

        extends: BaseModal,

        i18nOptions: { namespaces: ['intro', 'buttons', 'profile', 'modal_content'] },

        data () {
            return {
                modalName: 'login',
                loading: false,
            };
        },

        methods: {
            ...mapActions({
                logIn: 'profile/logIn',
                logOut: 'profile/logOut',
            }),

            ...mapMutations({
                updateProfile: 'profile/updateProfile',
            }),

            openRegModal () {
                this.closeModal();
                openModal(RegistrationModal);
            },
        },

    };
