
    export default {
        props: {
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            error: {
                type: String,
                required: true,
            },
            value: {
                type: Boolean,
                default: false,
            },
        },
    };
