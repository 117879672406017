
    export default {
        name: 'TearSVG',

        props: {
            color: {
                type: String,
                required: false,
                default: '#FFFFFF',
            },
        },
    };
