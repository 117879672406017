/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://github.com/rocketship-core/validator
 */
const
    { differenceInYears } = require('date-fns'),
    range = require('lodash/range'),
    isEmail = require('validator/lib/isEmail'),
    config = require('rocketship-config'),
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":3,"displayCAConfirm":true},
    // This is used to protect downstream systems with databases that can't handle
    // characters wider than three bytes, like FE2 and HW Analytics.
    // https://jiradc.helloworld.com/browse/SCU-144

    validReactions = ['like', 'love', 'laugh', 'sad', 'wow'],
    no4ByteChars = /^[\u{000000}-\u{00FFFF}]*$/u,
    noEmail = /^((?!@).)*$/,
    youtubeLink = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;

module.exports = {
    login: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    register: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
        mobile_phone_number: {
            required: true,
            isPhone: true,
        },
        'age.birth_day': { required: true },
        'age.birth_month': { required: true },
        'age.birth_year': { required: true },
        state: { required: true },
        zip: { required: true, isZip: true },
        rules: {
            // `isChecked` on its own, like other guards, is implicitly optional, i.e.
            // it's as if we are saying "if we get a value, it must look like a checked
            // checkbox", and `required` means "we must get a value" (and `false` is a
            // value), so we use both `required` and `isChecked` to fully require this
            // field to be checked.
            required: true,
            isChecked: true,
        },
        majority: {
            required: false,
            dynamicOnProvince (value, key, obj, opts) {
                const
                    provinceMap = {
                        AB: 18,
                        BC: 19,
                        MB: 18,
                        NB: 19,
                        NL: 19,
                        NS: 19,
                        NT: 19,
                        NU: 19,
                        ON: 18,
                        PE: 18,
                        QC: 18,
                        SK: 18,
                        YT: 19,
                    };

                // only validate this and run the logic if all fields are populated
                const required = ['state', 'age.birth_year', 'age.birth_month', 'age.birth_day'].filter((e) => e in obj);

                if (required.length < 4) {
                    return true;
                }

                const
                    currentTime = opts[0] || new Date(), // use current time as default for client-side, will still be validated server.
                    birthDate = new Date(
                        obj['age.birth_year'],
                        obj['age.birth_month'] - 1, // translate month number to month index
                        obj['age.birth_day'],
                    );

                const age = differenceInYears(currentTime, birthDate);
                const split = String(obj.state).split('-');
                const province = split.length === 1 ? split[0] : split[1];
                // we're good if they checked the box, they're under min age (so will hit ineligible), or if the age is already at province majority
                return age >= provinceMap[province] || ((age < 13 || age <= provinceMap[province]) && obj.majority == 1);
            },
        },
    },
    facilitySearch: {
        state: { required: true },
        query: { required: true },
    },
    facilityAdd: {
        state: { required: true },
        name: { required: true },
        city: { required: true },
        country: { required: true },
        zip: { required: true },
    },
    photoEntry: {
        facilityId: { required: true },
        facilityName: { required: true },
        facilityProvince: { required: true },
        photo: { required: true },
    },
    videoEntry: {
        facilityId: { required: true },
        facilityName: { required: true },
        facilityProvince: { required: true },
        video: { required: true },
    },
    youtubeEntry: {
        facilityId: { required: true },
        facilityName: { required: true },
        facilityProvince: { required: true },
        video: { required: true, youtubeLink },
    },
    storyEntry: {
        facilityId: { required: true },
        facilityName: { required: true },
        facilityProvince: { required: true },
        question1: { required: true, checkLengthOfStory },
        question2: { required: true, checkLengthOfStory },
        question3: { required: true, checkLengthOfStory },
    },
    noteEntry: {
        facilityId: { required: true },
        facilityName: { required: true },
        facilityProvince: { required: true },
        note: { required: true },
    },
    prelaunch: {
        email: {
            required: true,
            external: [isEmail],
        },
        locale: { required: true },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};

                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });

                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            no4ByteChars,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
    },
    reaction: {
        reaction: {
            required: true,
            isIn: validReactions,
        },
        facilityId: { required: true },
    },
    validReactions,
};

const viralGuards = module.exports.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: true, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}

function checkLengthOfStory (value, key, obj) {
    const validation = this;

    if (value.length < 150 || value.length > 1000) {
        validation.addError(key, 'LENGTH');
        return false;
    }
    return true;
}
