
    import { mapState, mapGetters, mapActions } from 'vuex';
    import TheHeader from '@components/TheHeader.vue';
    import HeaderNavigation from '@components/HeaderNavigation.vue';
    import TheFooter from '@components/TheFooter.vue';
    import openModal, { SessionExpirationModal } from './modals';
    import logClientError from './client-error';
    import webAnalytics from './plugins/WebAnalytics';

    export default {
        name: 'App',

        components: {
            TheHeader,
            HeaderNavigation,
            TheFooter,
        },

        i18nOptions: { namespaces: ['global'] },

        provide: {
            $webAnalytics: webAnalytics,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
                'ui',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
                isSessionExpired: 'profile/isSessionExpired',
                isModalOpen: 'ui/isModalOpen',
                isMenuOpen: 'ui/isMenuOpen',
            }),

            displayFooter () {
                return !['faq'].includes(this.ui.pageName.toLowerCase());
            },

            displayHeader () {
                return !['faq'].includes(this.ui.pageName.toLowerCase());
            },
        },

        watch: {
            isSessionExpired (value) {
                if (value) {
                    this.openSessionExpirationModal();
                }
            },
        },

        // If a fatal error occurs rendering any of our descendent components, try
        // to navigate to the error page to preserve some UX.
        // https://vuejs.org/v2/api/#errorCaptured
        errorCaptured (err) {
            this.$router.push('/error');
            // Forward fatal Vue component errors, i.e. those that caused us to display
            // the error page here, to './client-error' and its server-side logging.
            logClientError(err, { fatal: true });
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            async openSessionExpirationModal () {
                await openModal(SessionExpirationModal);
                // After the modal is closed.
                this.logOut();
            },
        },
    };
