
    import BaseModal from './BaseModal';

    export default {
        name: 'ErrorModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'error',
            };
        },

        i18nOptions: { namespaces: ['error'] },
    };
