
    import community from '../community';

    export default {
        name: 'FacilityList',
        props: {
            facilities: {
                type: Array,
                default: () => [],
            },
            hasMap: {
                type: Boolean,
                default: true,
            },
        },

        i18nOptions: { namespaces: ['facility_search'] },

        data () {
            return {
                facilityID: '',
            };
        },
        computed: {
            isFacilitiesFilledWithResults () {
                return this.facilities.length > 0;
            },
        },
        methods: {

            facilityName (facility) {
                return `${facility.data.name.trim()} (${facility.data.city.trim()}, ${facility.data.state.trim()})`;
            },

            selectFacilityMarker (facility) {
                if (this.facilityID === facility.data.id) {
                    return;
                }

                this.selectFacility(facility.id);
                this.$emit('selectmarker', facility.data.id);
            },

            selectFacility (facilityID) {
                this.facilityID = facilityID;
                this.$emit('scrollto');
            },

            goToFacility (facility) {
                this.$router.push({ path: community.getFacilityPath(facility) });
                this.$emit('close');
            },

            onScroll (event) {
                this.$emit('scroll', event);
            },

        },
    };
