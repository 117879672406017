
    import { nextTick } from 'vue';

    export default {
        props: {
            isError: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                required: true,
                default: '',
            },
            error: {
                type: String,
                required: true,
                default: '',
            },
            isAutomatedTest: {
                type: Boolean,
                required: true,
                default: false,
            },
        },

        computed: {
            recaptchaV2PublicKey () {
                return this.$store.state.app.recaptchaV2PublicKey;
            },
        },

        mounted () {
            window.recaptchaComplete = this.resizeCaptcha;
            window.recaptchaExpired = this.recaptchaExpired;

            nextTick(() => {
                const recaptchaInterval = window.setInterval(() => {
                    if (
                        window.grecaptcha &&
                        grecaptcha.render
                    ) {
                        if (this.$refs.gRecaptcha && this.$refs.gRecaptcha.matches(':empty')) {
                            grecaptcha.render(this.$refs.gRecaptcha, { sitekey: this.recaptchaV2PublicKey, 'expired-callback': window.recaptchaExpired });
                            this.resizeCaptcha();
                            window.addEventListener('resize', this.resizeCaptcha);
                            clearInterval(recaptchaInterval);
                        }
                    }
                }, 500);
            });
        },

        beforeDestroy () {
            window.removeEventListener('resize', this.resizeCaptcha);
        },

        methods: {
            resizeCaptcha () {
                const recaptchaContainerEl = document.querySelector('.g-recaptcha');
                // Get an accurate width from .g-recaptcha and getBoundingClientRect()
                const sizeEl = recaptchaContainerEl.children[0];
                const { width } = recaptchaContainerEl.getBoundingClientRect();
                sizeEl.style.transform = sizeEl.style.webkitTransform = 'scale(' + (width / 304) +  ')';
                // sizeEl.style.transformOrigin = 'top center';
                sizeEl.style.width = width + 'px';
                sizeEl.style.height = width * 78 / 304 + 'px'; // Default recaptcha is 304 x 78, convert using aspect ratio
            },

            // Needs a slight delay to actually resize it to the correct size.
            recaptchaExpired () {
                setTimeout(window.resizeCaptcha, 250);
            },

            reset () {
                if (window.grecaptcha) {
                    grecaptcha.reset();
                }
            },
        },
    };
