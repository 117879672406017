const moment = require('moment');

const provinceMap = {
    AB: 18,
    BC: 19,
    MB: 18,
    NB: 19,
    NL: 19,
    NS: 19,
    NT: 19,
    NU: 19,
    ON: 18,
    PE: 18,
    QC: 18,
    SK: 18,
    YT: 19,
};

module.exports.underAgeCheck = function underAgeCheck (province, age) {
    const prov = String(province).split('-')[1];
    return (
        (provinceMap[prov] && age < provinceMap[prov]) ||
        (province && age < 13)
    );
};

module.exports.getAge = function getAge (ageFields, currentMoment) {
    let missingField = false;
    ['month', 'day', 'year'].forEach((field) => {
        const key = 'age.birth_' + field;
        if (!isFilled(ageFields[key])) {
            missingField = true;
        }
    });

    if (missingField) {
        return false;
    }

    const birthDate = moment([
        ageFields['age.birth_year'],
        ageFields['age.birth_month'] - 1,
        ageFields['age.birth_day'],
    ]);

    if (!birthDate.isValid()) {
        return false;
    }

    // User is eligible if they are the minimum age as of the currentMoment.
    return currentMoment.diff(birthDate, 'years');
};

function isFilled (value) {
    if (value === null || value === undefined) return false;
    if (typeof value === 'object' && Object.keys(value).length === 0) { return false; }
    const str = String(value);
    if (!str || str.match(/^\s*$/)) return false;
    return true;
}
