
    import BaseModal from './BaseModal';

    export default {
        name: 'AddFacilityModalThanks',

        extends: BaseModal,

        i18nOptions: { namespaces: ['add_facility', 'global', 'buttons', 'modal_content'] },

        data () {
            return {
                modalName: 'add-facility-thanks',
            };
        },
    };
